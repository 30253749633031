import * as S from './styles';
import Logo from '../../assets/images/LOGO.png'
import { useNavigate } from 'react-router-dom';
import { Campanha, Categoria, Loja as LojaDictionary } from '../../shared/utils/dictionary';
import { isMobile } from '../../shared/utils/javascript';
import { Dropdown, MenuProps, Space } from 'antd';
import Search from 'antd/es/input/Search';
import { useDispatch, useSelector } from 'react-redux';
import { getCampanhas as getCampanhasSelector, getCategorias as getCategoriasSelector, getLoja } from '../../shared/redux/selectors';
import { useEffect, useState } from 'react';
import { AppDispatch } from '../../App';
import { filtroCampanha, getCampanhas, getCategorias, setSelectFiltro } from '../../shared/redux/slices/loja';
import { postWhats } from '../../service/api';
import axios from 'axios';

const Header = () => {
    const loja = useSelector(getLoja)

    const navigate = useNavigate()
    const categorias = useSelector(getCategoriasSelector)
    const campanhaSelector = useSelector(getCampanhasSelector)
    const [openSearch, setOpenSearch] = useState(false)
    const campanhasAtivas = campanhaSelector.filter(x => x.situacao)
    const campanha = campanhasAtivas?.find(campanha => {
        const dataInicio = new Date(campanha.dataInicio);
        const dataFim = new Date(campanha.dataFim);
        const dataAlvoFormatada = new Date();
      
        return dataAlvoFormatada >= dataInicio && dataAlvoFormatada <= dataFim;
      });
    const dispatch = useDispatch<AppDispatch>()
    const [ip, setIP] = useState()

    const getData = async () => {
  
      axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setIP(response.data.ip);
      })
      .catch(error => {
        console.log(error);
      });
      
    };
        
    useEffect(()=> {
      getData()
    },[ip])

    useEffect(() => {
        dispatch(getCategorias())
        dispatch(getCampanhas())
    },[])

    const criarArrayMenu = (campanha?: { nome: string; }) => {
        const items = categorias?.map((categoria, index) => ({
          key: index.toString(),
          label: (
            <a onClick={() => {
              navigate(`/estoque?categoria=${categoria.nome}`)
                dispatch(setSelectFiltro({valor: categoria.nome, tipo: "categoria"}))
            }} rel="noopener noreferrer">
              {categoria.nome}
            </a>
          ),
        }));

        const defaultPerso = {
            key: 'personalizada',
            label: (
              <a onClick={() => {
                  navigate('/cotar')
              }} rel="noopener noreferrer" href={`/cotar`}>
                Personalizada
              </a>
            ),
          }
        const defaultServicos = {
            key: 'servicos',
            label: (
              <a onClick={() => {
                  navigate('/servicos')
              }} rel="noopener noreferrer" href={`/servicos`}>
                Nossos serviços
              </a>
            ),
        }
        items.push(defaultServicos);
        items.push(defaultPerso);
      
        if (campanha) {
          const campanhaItem = {
            key: 'campanha',
            label: (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a onClick={() => {
                dispatch(filtroCampanha(campanha.nome))
                navigate(`/estoque?campanha=${campanha.nome}`)
            }} style={{color: "red"}} rel="noopener noreferrer">
                {campanha.nome}
              </a>
            ),
          };
      
          items.unshift(campanhaItem);
        }
        return items;
      };
      
      const items: MenuProps['items'] = criarArrayMenu(campanha);

    return (
        <S.Container>
            <S.ContainerHeader>
                {isMobile() ? (
                    <span>
                        <Dropdown className='dropdown' menu={{ items }}>
                            <a href='/' onClick={(e) => e.preventDefault()}>
                            <Space>
                                <i className="ri-menu-line ri-lg"></i>
                            </Space>
                            </a>
                        </Dropdown>
                    </span>
                ) : (
                    <span className={openSearch ? "open" : ""}>
                        <div className={openSearch ? 'search-box-colored' : 'search-box'}>
                            {openSearch && (<Search suffix={null} placeholder="Busque a sua joia" onSearch={(value) => {
                                navigate(`/estoque?busca=${value}`)
                            }} />)} 
                            <i onClick={() => {
                                setOpenSearch(true)
                            }} className="ri-search-line ri-lg" />
                        
                        </div>
                        <i onClick={() => {navigate("/contato")}} className="ri-map-pin-line ri-lg" />    
                    </span>
                    
                )}
                <div style={{display: "flex", justifyContent: "center"}}>
                    <img src={Logo} alt="Logo" onClick={()=> {
                        navigate('/')
                    }} />
                </div>
                <span style={{justifyContent: "end"}}>
                    {!isMobile() ? (
                        <i onClick={() => {
                            if(!ip) {
                                return
                                } else {
                                postWhats({
                                    ip: ip
                                })
                                }
                            window.open(`https://api.whatsapp.com/send?phone=+55${loja[LojaDictionary.WHATS]}&text=Olá, tudo bem? Estou acessando o site da *Joalheria Carvalho* e gostaria de mais informações`, '_blank')}
                        } className="ri-phone-line ri-lg" />
                    ) : null}
                </span>
            </S.ContainerHeader>
            {isMobile() ? (
                    <Search className='input-search' placeholder="Busque a sua joia" onSearch={(value) => {
                        navigate(`/estoque?busca=${value}`)
                    }} />
            ) : (
                <S.ContainerCategorias>
                    {campanha ? (
                        <h4 onClick={() => {
                            dispatch(filtroCampanha(campanha.nome))
                            navigate(`/estoque?campanha=${campanha.nome}`)
                        }} className='campanha'>{campanha[Campanha.NOME]}</h4>
                    ) : null}
                    {categorias.map((x)=>(
                        <h4 onClick={() => {
                            dispatch(setSelectFiltro({valor: x.nome, tipo: "categoria"}))
                            navigate(`/estoque?categoria=${x.nome}`)
                        }}>{x[Categoria.NOME]}</h4>
                    ))}
                    <h4 onClick={() => {
                            navigate("/cotar")
                        }}>Personalizada</h4>
                    <h4 onClick={() => {
                            navigate("/servicos")
                        }}>Nossos serviços</h4>
                </S.ContainerCategorias>
            )}
        </S.Container>
    )
}

export default Header;